import {
    BoutEventType,
    BoutSide,
    DualMeetSignatureKey,
    IBoutCore,
    IBoutEvent,
    ICollegeEvent,
    IDualMeet,
    IDualMeetBout,
    IDualMeetBoutFencer,
    IDualMeet_DB,
    IExistingFencer,
    IFencerRecord,
    ITeam,
    IUser,
    UserFlag,
    Weapon
} from "../types";
import { DB_V2, isSuccess } from "./database";

export const getRandomInt = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
};

/**
 * Pseudo-random number generator
 * @param seed RNG seed
 * @param min Minimum value
 * @param max Maximum value
 */
const PRNG = (seed: number, min: number, max: number) => {
    return () => {
        let t = (seed += 0x6d2b79f5);
        t = Math.imul(t ^ (t >>> 15), t | 1);
        t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
        const rand = ((t ^ (t >>> 14)) >>> 0) / 4294967296;
        return Math.floor(rand * (max - min) + min);
    };
};

export const genRandomStr = () => (Math.random() * 1e40).toString(36);

export const genRandomColor = () =>
    `rgba(${getRandomInt(0, 100)}, ${getRandomInt(0, 230)}, ${getRandomInt(0, 255)}, ${getRandomInt(40, 80) / 100})`;

export const genColorFromSeed = (seed: number | string) => {
    let num: number = 0;
    if (typeof seed === "number") {
        num = seed;
    } else {
        num = Number(
            seed
                .split("")
                .map(l => l.charCodeAt(0))
                .reduce((a, b) => a + b)
        );
    }
    const red = PRNG(num, 0, 100)();
    const green = PRNG(num, 0, 230)();
    const blue = PRNG(num, 0, 255)();
    const alpha = PRNG(num, 40, 80)();
    return `rgba(${red}, ${green}, ${blue}, ${alpha / 100})`;
};

type BoutWinnerOptions = Partial<{
    team: boolean;
    requireEnded: boolean;
}>;

/**
 * Gets the winner of a bout
 * @param cur The bout
 * @param team Whether or not to determine winner for team statistics (regular forfeits do not count for individual)
 * @returns The side, or `null` if no winner or if it should not count for team stats
 */
export const boutWinner = (cur: IBoutCore, options?: BoutWinnerOptions): BoutSide | null => {
    const team = options?.team ?? true;
    const requireEnded = options?.requireEnded ?? true;
    if (requireEnded && !cur.endedAt) return null;
    if (!cur.fencer1 || !cur.fencer2) return null;
    if (cur.fencer1.forfeit && cur.fencer2.forfeit) return null;
    // Forfeits only count for 1) team stats, 2) medical forfeits when the score was not reset to 0-0 (they were down when they forfeitted)
    const allowForfeitSide1 = team || (cur.fencer1.medicalForfeit && (cur.fencer1.score || cur.fencer2.score));
    const allowForfeitSide2 = team || (cur.fencer2.medicalForfeit && (cur.fencer1.score || cur.fencer2.score));
    if (cur.fencer1.forfeit && allowForfeitSide1) {
        return BoutSide.Fencer2;
    } else if (cur.fencer2.forfeit && allowForfeitSide2) {
        return BoutSide.Fencer1;
    } else if (cur.fencer1.score === 5 && cur.fencer2.score < 5) {
        return BoutSide.Fencer1;
    } else if (cur.fencer1.score > cur.fencer2.score && !!cur.endedAt) {
        return BoutSide.Fencer1;
    } else if (cur.fencer1.score === cur.fencer2.score && cur.priority === "left") {
        return BoutSide.Fencer1;
    } else if (cur.fencer2.score === 5 && cur.fencer1.score < 5) {
        return BoutSide.Fencer2;
    } else if (cur.fencer2.score > cur.fencer1.score && !!cur.endedAt) {
        return BoutSide.Fencer2;
    } else if (cur.fencer1.score === cur.fencer2.score && cur.priority === "right") {
        return BoutSide.Fencer2;
    }
    return null;
};

export const meetScoreFromBouts = (bouts: IDualMeetBout[]): [number, number] => {
    return bouts.reduce(
        (acc, cur) => {
            const winner = boutWinner(cur, { team: true });
            if (winner === null) return acc;
            if (winner === BoutSide.Fencer1) return [acc[0] + 1, acc[1]];
            if (winner === BoutSide.Fencer2) return [acc[0], acc[1] + 1];
            return acc;
        },
        [0, 0]
    );
};

export const clinchScoreFromBouts = (bouts: IDualMeetBout[]): [number, number] | null => {
    const score: [number, number] = [0, 0];
    for (const bout of bouts) {
        const winner = boutWinner(bout, { team: true });
        if (winner === BoutSide.Fencer1) score[0]++;
        if (winner === BoutSide.Fencer2) score[1]++;
        if (score[0] === 14 || score[1] === 14) return score;
    }
    return null;
};

export const meetScoreByWeapon = (bouts: IDualMeetBout[]): Record<Weapon, [number, number]> => {
    return {
        Sabre: meetScoreFromBouts(bouts.filter(l => l.weapon === "Sabre")),
        Foil: meetScoreFromBouts(bouts.filter(l => l.weapon === "Foil")),
        Epee: meetScoreFromBouts(bouts.filter(l => l.weapon === "Epee"))
    };
};

export const roundScoresFromBouts = (bouts: IDualMeetBout[]): [[number, number], [number, number], [number, number]] => {
    return [meetScoreFromBouts(bouts.slice(0, 9)), meetScoreFromBouts(bouts.slice(9, 18)), meetScoreFromBouts(bouts.slice(18))];
};

export const dualMeetScore = (DB: DB_V2, data: IDualMeet | IDualMeet_DB): [number, number] => {
    return [
        data.bouts.reduce((acc, cur) => acc + Number(cur.winner === BoutSide.Fencer1), 0),
        data.bouts.reduce((acc, cur) => acc + Number(cur.winner === BoutSide.Fencer2), 0)
    ] as [number, number];
};

export const dualMeetSquadScores = async (DB: DB_V2, data: IDualMeet | IDualMeet_DB) => {
    const boutResults = await Promise.all(data.bouts.map(l => DB.getBout(l.id)));
    const bouts = boutResults.filter(isSuccess).map(l => l.data);

    const sabreBouts = bouts.filter(l => l.weapon === "Sabre");
    const foilBouts = bouts.filter(l => l.weapon === "Foil");
    const epeeBouts = bouts.filter(l => l.weapon === "Epee");

    return {
        Sabre: meetScoreFromBouts(sabreBouts),
        Foil: meetScoreFromBouts(foilBouts),
        Epee: meetScoreFromBouts(epeeBouts)
    };
};

export const rosterMapFromTeam = async (DB: DB_V2, team: ITeam, season: string) => {
    const roster = team.roster?.[season];
    const rosterMap = {
        Sabre: new Map<IExistingFencer, { home: number; away: number; power?: number }>(),
        Foil: new Map<IExistingFencer, { home: number; away: number; power?: number }>(),
        Epee: new Map<IExistingFencer, { home: number; away: number; power?: number }>(),
        Armorer: new Map<IExistingFencer, { home: number; away: number; power?: number }>(),
        Unsorted: new Map<IExistingFencer, { home: number; away: number; power?: number }>()
    };
    if (roster) {
        // Generate map from fencer object to strip for each weapon
        const rosterExpanded = await Promise.all([
            Promise.all(Object.keys(roster.Sabre || {}).map(l => DB.getFencer(l))),
            Promise.all(Object.keys(roster.Foil || {}).map(l => DB.getFencer(l))),
            Promise.all(Object.keys(roster.Epee || {}).map(l => DB.getFencer(l))),
            Promise.all(Object.keys(roster.Armorer || {}).map(l => DB.getFencer(l))),
            Promise.all(Object.keys(roster.Unsorted || {}).map(l => DB.getFencer(l)))
        ]);
        for (const fencer of rosterExpanded[0]) {
            if (fencer.status === "fail") continue;
            rosterMap.Sabre.set(fencer.data, roster.Sabre[fencer.data.id]);
        }
        for (const fencer of rosterExpanded[1]) {
            if (fencer.status === "fail") continue;
            rosterMap.Foil.set(fencer.data, roster.Foil[fencer.data.id]);
        }
        for (const fencer of rosterExpanded[2]) {
            if (fencer.status === "fail") continue;
            rosterMap.Epee.set(fencer.data, roster.Epee[fencer.data.id]);
        }
        for (const fencer of rosterExpanded[3]) {
            if (fencer.status === "fail") continue;
            rosterMap.Armorer.set(fencer.data, roster.Armorer[fencer.data.id]);
        }
        for (const fencer of rosterExpanded[4]) {
            if (fencer.status === "fail") continue;
            rosterMap.Unsorted.set(fencer.data, roster.Unsorted[fencer.data.id]);
        }
    }
    return rosterMap;
};

export type IndividualStatsObj = Record<string, { name: string; score: [number, number] }>;
export type TeamIndividualStats = Record<"team1" | "team2", Record<Weapon, IndividualStatsObj>>;

/**
 * Gets individual stats for a list of bouts, agnostic to weapon. Primarily used for college
 */
export const getIndividualStatsForBouts = (bouts: IDualMeetBout[]): Record<"team1" | "team2", IndividualStatsObj> => {
    const stats: Record<"team1" | "team2", IndividualStatsObj> = {
        team1: {},
        team2: {}
    };

    for (const bout of bouts) {
        const winner = boutWinner(bout, { team: false });

        if (winner === BoutSide.Fencer1) {
            const name1 = `${bout.fencer1.fencerInfo.firstName} ${bout.fencer1.fencerInfo.lastName}`;
            const name2 = `${bout.fencer2.fencerInfo.firstName} ${bout.fencer2.fencerInfo.lastName}`;
            if (name1 !== "Unknown fencer") {
                if (bout.fencer1.fencerInfo.id) {
                    const fencer1Stats = stats.team1[bout.fencer1.fencerInfo.id!];
                    if (fencer1Stats) {
                        stats.team1[bout.fencer1.fencerInfo.id].score = [fencer1Stats.score[0] + 1, fencer1Stats.score[1]];
                    } else {
                        stats.team1[bout.fencer1.fencerInfo.id] = { name: name1, score: [1, 0] };
                    }
                } else {
                    if (stats.team1[`writeIn${name1}`]) {
                        stats.team1[`writeIn${name1}`].score[0]++;
                    } else {
                        stats.team1[`writeIn${name1}`] = {
                            name: name1,
                            score: [1, 0]
                        };
                    }
                }
            }
            if (name2 !== "Unknown fencer") {
                if (bout.fencer2.fencerInfo.id) {
                    const fencer2Stats = stats.team2[bout.fencer2.fencerInfo.id!];
                    if (fencer2Stats) {
                        stats.team2[bout.fencer2.fencerInfo.id].score = [fencer2Stats.score[0], fencer2Stats.score[1] + 1];
                    } else {
                        stats.team2[bout.fencer2.fencerInfo.id] = { name: name2, score: [0, 1] };
                    }
                } else {
                    if (stats.team2[`writeIn${name2}`]) {
                        stats.team2[`writeIn${name2}`].score[1]++;
                    } else {
                        stats.team2[`writeIn${name2}`] = {
                            name: name2,
                            score: [0, 1]
                        };
                    }
                }
            }
        }

        if (winner === BoutSide.Fencer2) {
            const name1 = `${bout.fencer1.fencerInfo.firstName} ${bout.fencer1.fencerInfo.lastName}`;
            const name2 = `${bout.fencer2.fencerInfo.firstName} ${bout.fencer2.fencerInfo.lastName}`;
            if (name1 !== "Unknown fencer") {
                if (bout.fencer1.fencerInfo.id) {
                    const fencer1Stats = stats.team1[bout.fencer1.fencerInfo.id];
                    if (fencer1Stats) {
                        stats.team1[bout.fencer1.fencerInfo.id].score = [fencer1Stats.score[0], fencer1Stats.score[1] + 1] as [
                            number,
                            number
                        ];
                    } else {
                        stats.team1[bout.fencer1.fencerInfo.id] = { name: name1, score: [0, 1] };
                    }
                } else {
                    if (stats.team1[`writeIn${name1}`]) {
                        stats.team1[`writeIn${name1}`].score[1]++;
                    } else {
                        stats.team1[`writeIn${name1}`] = {
                            name: name1,
                            score: [0, 1]
                        };
                    }
                }
            }
            if (name2 !== "Unknown fencer") {
                if (bout.fencer2.fencerInfo.id) {
                    const fencer2Stats = stats.team2[bout.fencer2.fencerInfo.id];
                    if (fencer2Stats) {
                        stats.team2[bout.fencer2.fencerInfo.id].score = [fencer2Stats.score[0] + 1, fencer2Stats.score[1]] as [
                            number,
                            number
                        ];
                    } else {
                        stats.team2[bout.fencer2.fencerInfo.id] = { name: name2, score: [1, 0] };
                    }
                } else {
                    if (stats.team2[`writeIn${name2}`]) {
                        stats.team2[`writeIn${name2}`].score[0]++;
                    } else {
                        stats.team2[`writeIn${name2}`] = {
                            name: name2,
                            score: [1, 0]
                        };
                    }
                }
            }
        }
    }

    return stats;
};

/**
 * Gets individual stats for a list of bouts, separated by weapon. Primarily used for high school
 */
export const getIndividualStatsForMeet = (bouts: IDualMeetBout[]): TeamIndividualStats => {
    const stats: TeamIndividualStats = {
        team1: { Sabre: {}, Foil: {}, Epee: {} },
        team2: { Sabre: {}, Foil: {}, Epee: {} }
    };

    const sabreBouts = bouts.filter(l => l.weapon === "Sabre");
    const foilBouts = bouts.filter(l => l.weapon === "Foil");
    const epeeBouts = bouts.filter(l => l.weapon === "Epee");

    {
        const { team1, team2 } = getIndividualStatsForBouts(sabreBouts);
        stats.team1.Sabre = team1;
        stats.team2.Sabre = team2;
    }
    {
        const { team1, team2 } = getIndividualStatsForBouts(foilBouts);
        stats.team1.Foil = team1;
        stats.team2.Foil = team2;
    }
    {
        const { team1, team2 } = getIndividualStatsForBouts(epeeBouts);
        stats.team1.Epee = team1;
        stats.team2.Epee = team2;
    }

    return stats;
};

export const extractFromRole = (role: number, home: boolean) => {
    if (home) {
        if (role & (1 << 3)) return 3;
        if (role & (1 << 2)) return 2;
        if (role & (1 << 1)) return 1;
        return 0;
    } else {
        if (role & (1 << 6)) return 6;
        if (role & (1 << 5)) return 5;
        if (role & (1 << 4)) return 4;
        return 7;
    }
};

export const homeMeetOrder = [
    "S3",
    "S1",
    "S2",
    "F3",
    "F1",
    "F2",
    "E3",
    "E1",
    "E2",
    "S1",
    "S3",
    "S2",
    "F1",
    "F3",
    "F2",
    "E1",
    "E3",
    "E2",
    "S1",
    "S2",
    "S3",
    "F1",
    "F2",
    "F3",
    "E1",
    "E2",
    "E3"
];

export const awayMeetOrder = [
    "S6",
    "S5",
    "S4",
    "F6",
    "F5",
    "F4",
    "E6",
    "E5",
    "E4",
    "S6",
    "S4",
    "S5",
    "F6",
    "F4",
    "F5",
    "E6",
    "E4",
    "E5",
    "S4",
    "S6",
    "S5",
    "F4",
    "F6",
    "F5",
    "E4",
    "E6",
    "E5"
];

export const genAbbreviationFromName = (name: string) => {
    const splitStr = name.split(" ");
    const mens = splitStr.some(l => l.toLowerCase() === "men's");
    const womens = splitStr.some(l => l.toLowerCase() === "women's");
    const baseAbb = splitStr
        .filter(l => l !== "Men's" && l !== "Women's")
        .join(" ")
        .replace(/[a-z ]/g, "");

    const result = `${baseAbb} ${mens ? "Men's" : womens ? "Women's" : ""}`;
    return result;
};

const formatArrs = (a: string): [number, number][] => a.split(" ").map(l => l.split("-").map(Number) as [number, number]);

export const TournamentOrderArrs: Record<number, [number, number][]> = {
    2: formatArrs("1-2"),
    3: formatArrs("1-2 2-3 3-1"),
    4: formatArrs("1-4 2-3 3-1 2-4 3-4 1-2"),
    5: formatArrs("1-2 3-4 5-1 2-3 5-4 1-3 2-5 4-1 3-5 4-2"),
    6: formatArrs("1-2 4-3 5-6 3-1 2-6 5-4 1-6 3-5 4-2 5-1 4-6 2-3 1-4 5-2 3-6"),
    7: formatArrs("1-4 2-5 3-6 7-1 5-4 2-3 6-7 5-1 4-3 6-2 5-7 3-1 4-6 7-2 3-5 1-6 2-4 7-3 6-5 1-2 4-7"),
    8: formatArrs("2-3 1-5 7-4 6-8 1-2 3-4 5-6 8-7 4-1 5-2 8-3 6-7 4-2 8-1 7-5 3-6 2-8 7-4 6-1 3-7 4-8 2-6 3-5 1-7 4-6 8-5 7-2 1-3"),
    9: formatArrs(
        "1-9 2-8 3-7 4-6 1-5 2-9 8-3 7-4 6-5 1-2 9-3 8-4 7-5 6-1 3-2 9-4 5-8 7-6 3-1 2-4 5-9 8-6 7-1 4-3 5-2 6-9 8-7 4-1 5-3 6-2 9-7 1-8 4-5 3-6 2-7 9-8"
    ),
    10: formatArrs(
        "1-4 6-9 2-5 7-10 3-1 8-6 4-5 9-10 2-3 7-8 5-1 10-6 4-2 9-7 5-3 10-8 1-2 6-7 3-4 8-9 5-10 1-6 2-7 3-8 4-9 6-5 10-2 8-1 7-4 9-3 2-6 5-8 4-10 1-9 3-7 8-2 6-4 9-5 10-3 7-1 4-8 2-9 3-6 5-7 1-10"
    ),
    11: formatArrs(
        "1-2 7-8 4-5 10-11 2-3 8-9 5-6 3-1 9-7 6-4 2-5 8-11 1-4 7-10 5-3 11-9 1-6 4-2 10-8 3-6 5-1 11-7 3-4 9-10 6-2 1-7 3-9 10-4 8-2 5-11 1-8 9-2 3-10 4-11 6-7 9-1 2-10 11-3 7-5 6-8 10-1 11-2 4-7 8-5 6-9 11-1 7-3 4-8 9-5 6-10 2-7 8-3 4-9 10-5 6-11"
    ),
    12: formatArrs(
        "1-2 7-8 4-5 10-11 2-3 8-9 5-6 11-12 3-1 9-7 6-4 12-10 2-5 8-11 1-4 7-10 5-3 11-9 1-6 7-12 4-2 10-8 3-6 9-12 5-1 11-7 3-4 9-10 6-2 12-8 1-7 3-9 10-4 8-2 5-11 12-6 1-8 9-2 3-10 4-11 12-5 6-7 9-1 2-10 11-3 4-12 7-5 6-8 10-1 11-2 12-3 4-7 8-5 6-9 11-1 2-12 7-3 4-8 9-5 6-10 12-1 2-7 8-3 4-9 10-5 6-11"
    )
};

export const genOrderArr = (num: number) =>
    Array(num)
        .fill(1)
        .map((_, idx) => idx + 1)
        .map((v, i, arr) => arr.slice(i + 1).map(w => [v, w]))
        .flat() as [number, number][];

export const getMeetsFromEvent = (event: ICollegeEvent) => {
    const meets: string[] = [];

    for (const round of event.mensRounds) {
        for (const meet of round.meets) {
            meets.push(meet.id);
        }
    }
    for (const round of event.womensRounds) {
        for (const meet of round.meets) {
            meets.push(meet.id);
        }
    }

    return meets;
};

export const getTeamIDsFromEvent = (event: ICollegeEvent) => {
    const teams: Set<string> = new Set();

    for (const round of event.mensRounds) {
        for (const meet of round.meets) {
            teams.add(meet.idA);
            teams.add(meet.idB);
        }
    }
    for (const round of event.womensRounds) {
        for (const meet of round.meets) {
            teams.add(meet.id);
        }
    }

    return [...teams].filter(l => !l.startsWith("writeIn"));
};

export const GenTournamentOrder = (teamCount: number, stripCount: number, bannedPairs: [number, number][]) => {
    if (stripCount >= Math.floor(teamCount / 2)) {
        const teams = teamCount;

        const rounds = Math.ceil(teams / 2) * 2;
        const mpr = Math.floor((rounds + 1) / 2);

        const table = Array(rounds)
            .fill(0)
            .map((_, idx) => idx + 1);

        const matches: [number, number][][] = [];
        for (let i = 0; i < rounds - 1; i++) {
            matches.push([]);
            for (let j = 0; j < mpr; j++) {
                matches[i].push([table[j], table[table.length - 1 - j]]);
            }
            const removed = table.pop();
            table.splice(1, 0, removed!);
        }

        if (teams % 2 !== 0) {
            for (let i = 0; i < matches.length; i++) {
                matches[i] = matches[i].filter(j => !j.includes(teams + 1));
            }
        }

        for (let i = 0; i < matches.length; i++) {
            matches[i] = matches[i].filter(
                j => !bannedPairs.some(k => (k[0] === j[0] && k[1] === j[1]) || (k[0] === j[1] && k[1] === j[0]))
            );
        }

        return matches;
    } else {
        let possiblePairings = (TournamentOrderArrs[teamCount] || genOrderArr(teamCount)).filter(
            l => !bannedPairs.some(j => j[0] === l[0] && j[1] === l[1])
        );
        const rounds: [number, number][][] = [];

        let failCount = 0;
        const failLimit = 1000;

        while (possiblePairings.length && failCount++ < failLimit) {
            const currentRound: [number, number][] = [];
            rounds.push(currentRound);

            while (failCount++ < failLimit) {
                const teamsInRound = currentRound.flat();
                const validPairingsForRound = possiblePairings.filter(l => !teamsInRound.includes(l[0]) && !teamsInRound.includes(l[1]));

                if (validPairingsForRound.length === 0) break;
                if (currentRound.length >= stripCount) break;

                const pairing = validPairingsForRound[0];

                currentRound.push(pairing);
                possiblePairings = possiblePairings.filter(l => l[0] !== pairing[0] || l[1] !== pairing[1]);
            }
        }

        return rounds;
    }
};

export const userCanSeeMeet = (meet: IDualMeet | IDualMeet_DB, userInfo?: IUser | null) => {
    if (meet.published) return true;
    if ((userInfo?.flags || 0) & UserFlag.MeetManager) return true;
    const team1 = ("team1" in meet ? meet.team1.id : meet.team1ID) || "asdf";
    const team2 = ("team2" in meet ? meet.team2.id : meet.team2ID) || "asdf";
    if (userInfo?.teams?.includes(team1)) return true;
    if (userInfo?.teams?.includes(team2)) return true;
    if (userInfo?.managingTeams.includes(team1)) return true;
    if (userInfo?.managingTeams.includes(team2)) return true;
    return false;
};

export const userCanSeeTeam = (team: ITeam, userInfo?: IUser | null) => {
    if (team.published) return true;
    if ((userInfo?.flags || 0) & UserFlag.MeetManager) return true;
    if (userInfo?.teams?.includes(team.id)) return true;
    if (userInfo?.managingTeams?.includes(team.id)) return true;
    return false;
};

export const eventsToScoreProgression = (events: IBoutEvent[]) => {
    const res: (BoutSide | 3)[] = [];

    let lastScore = [0, 0];
    for (const event of events) {
        let changeStatus = 0;
        if (event.score1 > lastScore[0]) {
            changeStatus = 1;
        } else if (event.score1 < lastScore[0]) {
            const lastAddition = res.findLastIndex(l => l === 1 || l === 3);
            if (lastAddition !== -1) {
                if (res[lastAddition] === 3) {
                    res[lastAddition] = 2;
                } else {
                    res.splice(lastAddition, 1);
                }
            }
        }
        if (event.score2 > lastScore[1]) {
            changeStatus += 2;
        } else if (event.score2 < lastScore[1]) {
            const lastAddition = res.findLastIndex(l => l === 2 || l === 3);
            if (lastAddition !== -1) {
                if (res[lastAddition] === 3) {
                    res[lastAddition] = 1;
                } else {
                    res.splice(lastAddition, 1);
                }
            }
        }
        if (changeStatus) {
            res.push(changeStatus);
        }
        lastScore = [event.score1, event.score2];
    }

    return res;
};

export const weaponToSignatureKey = {
    Sabre: "sabreRef",
    Foil: "foilRef",
    Epee: "epeeRef",
    "Team A": "team1",
    "Team B": "team2",
    "Home Team": "team1",
    "Away Team": "team2",
    "Team A Sabre": "team1Sabre",
    "Team A Foil": "team1Foil",
    "Team A Epee": "team1Epee",
    "Team B Sabre": "team2Sabre",
    "Team B Foil": "team2Foil",
    "Team B Epee": "team2Epee",
    "Home Team Sabre": "team1Sabre",
    "Home Team Foil": "team1Foil",
    "Home Team Epee": "team1Epee",
    "Away Team Sabre": "team2Sabre",
    "Away Team Foil": "team2Foil",
    "Away Team Epee": "team2Epee",
    "Meet Referee": "sabreRef"
};

export const removeGenderFromStr = (str: string) =>
    str
        .replace(/ Men('|’)s/g, "")
        .replace(/ Women('|’)s/g, "")
        .trim();

export const schoolNamesEqual = (name1: string, name2: string) => {
    const lc1 = name1.toLowerCase();
    const lc2 = name2.toLowerCase();

    if (lc1.trim() === lc2.trim()) return true;
    if (lc1.replace("High School", "").trim() === lc2.replace("High School", "").trim()) return true;

    return false;
};

/**
 * Increments the last character of a string
 * @example
 * incrementString("blah") // returns "blai"
 * incrementString("blaz") // returns "blb"
 * @param str
 * @returns
 */
export const incrementString = (str: string): string => {
    if (str.length === 0) return str;

    const lastIndex = str.length - 1;
    const chars = str.split("");
    let i = lastIndex;

    while (i >= 0) {
        if (chars[i] === "z") {
            chars[i] = "a";
            i--;
        } else if (chars[i] === "Z") {
            chars[i] = "A";
            i--;
        } else if ((chars[i] >= "a" && chars[i] < "z") || (chars[i] >= "A" && chars[i] < "Z")) {
            chars[i] = String.fromCharCode(chars[i].charCodeAt(0) + 1);
            break;
        } else {
            // If the character is non-alphabetic, we will not increment it.
            break;
        }
    }

    if (i < 0) {
        return str;
    }

    return chars.join("");
};

/**
 * Get the full name of an `IExistingFencer`
 * @param fencer
 * @param options
 * @returns
 */
export const fencerName = (fencer: IExistingFencer, options?: { gradYear: boolean; record: string }) => {
    let name = `${fencer.firstName} ${fencer.lastName}`;
    if (options?.gradYear && fencer.gradYear) {
        name += ` - ${fencer.gradYear}`;
    }
    if (options?.record) {
        name += ` - ${options.record}`;
    }
    return name;
};

export const boutFencerName = (fencer: IDualMeetBoutFencer) => {
    let name = `${fencer.fencerInfo.firstName} ${fencer.fencerInfo.lastName}`;
    if (fencer.forfeit && !fencer.medicalForfeit) name = "No fencer";
    return name;
}

/**
 * Gets width of text at a specified font and size, utilizing the HTML5 Canvas API.
 * @param str The text to measure
 * @param options Options to draw text
 */
export const textWidth = (str: string, { font, size }: { font: string; size: number }) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d")!;
    ctx.font = `${size}px ${font}`;
    const metrics = ctx.measureText(str);
    return metrics.width;
};
