export const VERSION = "2.0.0";

export const ERROR_UNKNOWN = "An error occurred.";
export const ERROR_INVALID_ID = "The provided ID is invalid.";
export const ERROR_NOT_LOGGED_IN = "You are not logged in.";
export const ERROR_ACCOUNT_ISSUE = "There is an issue with your account.";
export const ERROR_USER_DOES_NOT_EXIST = "That user does not exist.";
export const ERROR_BOUT_DOES_NOT_EXIST = "That bout does not exist.";
export const ERROR_TEAM_DOES_NOT_EXIST = "That team does not exist.";
export const ERROR_EVENT_DOES_NOT_EXIST = "That event does not exist.";
export const ERROR_INVITE_DOES_NOT_EXIST = "That invite does not exist.";
export const ERROR_SCHOOL_DOES_NOT_EXIST = "That school does not exist.";
export const ERROR_FENCER_DOES_NOT_EXIST = "That fencer does not exist.";
export const ERROR_DUAL_MEET_DOES_NOT_EXIST = "That dual meet does not exist.";
export const ERROR_BOUT_EVENT_DOES_NOT_EXIST = "That bout event does not exist.";
export const ERROR_FENCER_RECORDS_DOES_NOT_EXIST = "That fencer's records do not exist.";
export const ERROR_PROCESSING_MEET = "There was an error with processing a meet.";
export const ERROR_INVALID_INVITE = "That invite code is invalid.";
export const ERROR_INVITE_CLAIMED = "The invite code has already been claimed.";
export const ERROR_CANNOT_MOVE_MEET = "The meet cannot be moved.";
export const ERROR_MEET_NOT_IN_SCHEDULE = "The meet is not in the schedule.";
export const ERROR_ADDING_LINEUPS = "An error occurred when adding lineups.";

export const GROWN_UP_STR = {
    boys: "man",
    girls: "woman",
    both: "both"
} as const;
